/* stylelint-disable scss/at-extend-no-missing-placeholder */
button:focus, .btn:focus {
  outline: none;
}

%base-button {
  display: inline-block;
  margin-right: 10px;
  padding: 8px 10px;
  border: 1px solid $k-gray;
  border-radius: $border-radius-base;
  outline: none;
  background-color: $bg-color;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  appearance: none;

  &.btn-fill {
    width: 100%;
  }

  &.allow-centering {
    margin: 0;
  }

  &:hover, &:active {
    border: 1px solid $k-gray;
    background-color: $light-gray-bg;
    box-shadow: none;
    text-decoration: none;
  }

  &[disabled="disabled"] {
    border: 1px solid $grey-3;
    background-color: $grey-2;
    color: $grey-4;
    @include opacity(.75);
  }

  &[disabled="disabled"]:hover, &[disabled="disabled"]:active {
    border: 1px solid $grey-3;
    background-color: $grey-2;
    box-shadow: none;
  }
}

.priori-btn, a.priori-btn, .btn {
  @extend %base-button;
  color: $k-darker-gray;

  &.no-margin {
    margin: 0;
  }

  &:hover, &:active, &:focus {
    color: $k-darker-gray;
  }
}

.compact-btn {
  @extend %base-button;
  margin-right: 0;
  padding: 4px 6px;
  background-color: $k-light-gray;
  font-size: 13px;
}

.small-btn, a.small-btn.btn, .kenny-styles .small-btn {
  @extend %base-button;
  padding: 6px 8px;
  font-size: 11px;
  font-weight: 600;
}

.green-btn, a.green-btn.btn, .kenny-styles .green-btn {
  @extend %base-button;
  border: 1px solid $k-green;
  background-color: $k-light-green-bg;
  color: $k-green;

  &:hover, &:active {
    border: 1px solid $k-green;
    background-color: darken($k-light-green-bg, 2%);
  }

  &[disabled="disabled"], &[disabled="disabled"]:hover, &[disabled="disabled"]:active {
    border: 1px solid $k-green;
    background-color: $k-light-green-bg;
    color: $k-green;
    @include opacity(.5);
  }

  .base-icon {
    fill: $k-green;
  }
}

.compact-btn-gray {
  @include a-button-small($k-darker-gray, $k-gray, $k-darker-gray, $light-gray-bg);

  &:hover, &:active {
    border-color: $k-gray;
  }
}

.compact-btn-blue {
  @include a-button-small($k-blue, $k-blue, $white, $k-blue);
}

.compact-btn-yellow {
  @include a-button-small($k-yellow, $k-yellow, $white, $k-yellow);
}

.compact-btn-green {
  @include a-button-small($k-green, $k-green, $white, $k-green);

  &:focus {
    color: $white;
  }

  &:focus:not(:active):not(:hover) {
    color: $k-green;
  }
}

.compact-btn-red {
  @include a-button-small($k-red, $k-red, $white, $k-red);
}

.icon {
  position: relative;
  top: .1em;
  font-size: 90%;
  text-decoration: inherit;
}

.input-group-btn .btn {
  margin-right: 0;
  padding: 6px 12px !important;
  font-size: 14px;
}

.toggle-container {
  display: inline-block;
  position: relative;
  width: fit-content;
}

.toggle-button {
  height: 30px;
  padding: 1px 31px 1px 1px;
  border: 1px solid $k-light-gray;
  border-radius: 31px;
  outline: none;
  background-color: $bg-color;
  vertical-align: middle;

  &::after {
    content: "";
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 2px 6px 0 rgba($black, .2);
  }

  &.toggle-on {
    padding: 1px 1px 1px 31px;
    border: 1px solid $k-green;
    background-color: $k-green;
  }

  &.blue-toggle.toggle-on {
    border-color: $k-blue;
    background-color: $k-blue;
  }

  &.smaller {
    height: 25px;
    padding: 1px 26px 1px 1px;
    border-radius: 26px;

    &::after {
      width: 21px;
      height: 21px;
      box-shadow: 0 1px 4px 0 rgba($black, .2);
    }

    &.toggle-on {
      padding: 1px 1px 1px 26px;
    }
  }

  &.with-legend {
    padding: 1px 10px 1px 31px;

    &::after {
      left: 2px;
      position: absolute;
      top: 2px;
    }

    &.toggle-on {
      color: $white;
      padding: 1px 31px 1px 10px;

      &::after {
        left: auto;
        right: 2px;
      }
    }
  }
}

.scout-toggle-button {
  position: relative;
  height: 15px;
  padding: 1px 6px 3px 17px;
  border: 0;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  color: $white;
  outline: none;
  background-color: $darker-gray;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: $white;
    border-radius: 50%;
    border: 2px solid $darker-gray;
  }

  &.toggle-on {
    padding: 1px 17px 3px 6px;
    background-color: $k-blue;

    &::before {
      left: auto;
      right: 0;
      border: 2px solid $k-blue;
    }
  }

  &.lg {
    height: 20px;
    border-radius: 10px;
    padding: 3px 7px 3px 22px;

    &.toggle-on {
      padding: 3px 23px 3px 7px;
    }

    &::before {
      height: 20px;
      width: 20px;
    }
  }
}

.yes-no-btn-group {
  button {
    display: inline-block;
    width: 70px;
    height: 60px;
    margin: 0;
    border: 1px solid $k-gray;
    border-radius: $border-radius-base;
    background: $white;
    color: $k-dark-gray;
    font-size: 13px;
    font-weight: 600;
    text-align: center;

    &:first-child {
      margin-right: -2px;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      margin-left: -3px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.selected {
      border-color: $k-blue;
      background-color: $k-blue;
      color: $white;

      &.selected-no {
        border-color: $k-dark-gray;
        background-color: $k-dark-gray;
      }
    }

    &:not(.selected):hover {
      background-color: $bg-color;
    }

    &.half-width {
      width: 49%;
    }

    &.compact {
      width: 50px;
      height: 30px;
      font-size: 11px;
    }
  }

  &.disabled button {
    cursor: not-allowed;

    &.selected {
      border-color: $k-gray;
      background-color: $k-gray;
    }
  }
}

.two-btn-group, .three-btn-group {
  @include clearfix;

  button {
    display: block;
    height: 60px;
    margin: 0;
    padding: 0 4px;
    float: left;
    border: 1px solid $k-light-gray;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    background-color: $white;
    color: $k-gray;
    font-size: 13px;
    font-weight: 600;
    text-align: center;

    &:first-child {
      margin-left: 0;
      border-left: 1px solid $k-light-gray;
      border-top-left-radius: $border-radius-large;
      border-bottom-left-radius: $border-radius-large;
    }

    &:last-child {
      border-right: 1px solid $k-light-gray;
      border-top-right-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
    }

    &:hover, &:disabled {
      background-color: $bg-color;
    }

    &.selected {
      border: 0;
      background: $k-purple;
      background: linear-gradient(45deg, $k-purple, $k-red);
      color: $white;

      &:hover {
        background: $k-purple;
        background: linear-gradient(45deg, $k-purple, $k-red);
      }
    }
  }

  &.alternate-group {
    button {
      height: 34px;
      border-color: $k-gray;
      color: $k-dark-gray;

      &:first-child {
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;
      }

      &:last-child {
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
      }

      &.selected {
        background: $k-blue;
        color: $white;

        &:hover {
          background: $k-blue;
        }
      }
    }
  }
}

.two-btn-group button {
  width: 50%;
}

.three-btn-group button {
  width: 34%;

  &:first-child, &:last-child {
    width: 33%;
  }
}

.nv-button, a.nv-button {
  display: inline-block;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  border: 1px solid rgba($white, .15);
  border-radius: $border-radius-large;
  outline: none;
  background-color: transparent;
  color: $white;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  appearance: none;

  &:hover, &:active {
    background-color: rgba($white, .15);
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }

  &.disabled, &:disabled {
    cursor: default;
    pointer-events: none;
    @include opacity(.5);

    .base-icon {
      @include opacity(.5);
    }

    &.disabled-gray {
      border: 1px solid $k-gray;
      background-color: $k-gray;
    }
  }

  &.with-shadow {
    @include k-shadow;
  }

  &.smaller {
    height: 40px;
    padding: 0 10px;
  }

  &.a-button-size {
    height: 46px;
    padding: 0 11px;

    @media (min-width: $screen-sm-min) {
      height: 42px;
    }
  }

  &.no-transform {
    font-weight: 600;
    text-transform: none;
  }

  &.fit-content {
    width: unset;
    padding-left: 25px;
    padding-right: 25px;
  }
}

a.nv-button {
  padding-top: 20px;
  padding-bottom: 20px;

  &.smaller {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &:focus {
    color: $white;
    text-decoration: none;
  }
}

.nv-button-white, a.nv-button-white {
  @extend .nv-button;
  border: 1px solid $k-light-gray;
  background-color: $white;
  fill: $body-text-color;
  color: $body-text-color;

  &:hover, &:active, &.selected {
    border-color: $body-text-color;
    background-color: $bg-color;
    color: $body-text-color;
  }

  &:focus {
    color: $body-text-color;
  }

  &.no-border {
    padding-top: 21px;
    padding-bottom: 21px;
    border: 0;

    &.smaller {
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  &.dark-border {
    border-color: $body-text-color;
  }
}

.secondary-btn-blue, a.secondary-btn-blue {
  @extend .nv-button;
  @include secondary-button($k-blue, $k-blue, $white);
}

.secondary-btn-red, a.secondary-btn-red {
  @extend .nv-button;
  @include secondary-button($k-red, $k-red, $white);
}

.secondary-btn-green, a.secondary-btn-green {
  @extend .nv-button;
  @include secondary-button($k-green, $k-green, $white);
}

.secondary-btn-darker-gray, a.secondary-btn-darker-gray {
  @extend .nv-button;
  @include secondary-button($body-text-color, $body-text-color, $white);
}

.secondary-btn-clear-inverted, a.secondary-btn-clear-inverted {
  @extend .nv-button;
  border: 1px solid rgba($white, .3);
  background-color: transparent;
  color: $white;

  &:hover, &:active {
    border-color: $white;
    background-color: rgba($white, .1);
    color: $white;
  }
}

.nv-button-dashed, a.nv-button-dashed {
  @extend .nv-button;
  border: 1px dashed $k-light-gray;
  background-color: $white;
  fill: $body-text-color;
  color: $body-text-color;

  &:hover, &:active {
    background-color: $bg-color;
    color: $body-text-color;
  }

  &:focus {
    color: $body-text-color;
  }
}

.nv-button-red, a.nv-button-red {
  @extend .nv-button;
  border: 0;
  background-color: $k-red;

  &.inverse {
    border: 1px solid $k-light-gray;
    background-color: $white;
    color: $k-red;

    &:hover, &:active {
      border-color: $k-red;
      background-color: $white;
    }
  }

  &:hover, &:active {
    background-color: darken($k-red, 10%);
  }
}

.nv-button-green, a.nv-button-green {
  @extend .nv-button;
  border-color: $k-green;
  background-color: $k-light-green-bg;
  color: $k-green;

  &:hover, &:active {
    background-color: darken($k-light-green-bg, 3%);
    color: $k-green;
  }

  &:focus {
    color: $k-green;
  }
}

.nv-button-dark-green, a.nv-button-dark-green {
  @extend .nv-button;
  border-color: $k-green;
  background-color: $k-green;
  color: $white;

  &:hover, &:active {
    background-color: lighten($k-green, 5%);
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.nv-button-blue, a.nv-button-blue, .primary-btn-blue {
  @extend .nv-button;
  border-color: $k-blue;
  background-color: $k-blue;
  color: $white;

  &:hover, &:active {
    border-color: lighten($k-blue, 3%);
    background-color: lighten($k-blue, 3%);
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.nv-button-purple-red, a.nv-button-purple-red {
  @extend .nv-button;
  border: 0;
  background: #62087A;
  background: linear-gradient(45deg, #62087A, #AF2E8D);

  &:hover, &:active {
    background: darken(#62087A, 10%);
    background: linear-gradient(45deg, darken(#62087A, 10%), darken(#AF2E8D, 10%));
  }
}

.nv-button-white-purple {
  @extend .nv-button;
  background: white;
  border: 1px solid $k-gray;
  color: $body-text-color;

  &.active, &:hover, &:active {
    background: $light-purple;
    border-color: $k-purple;
    color: $body-text-color;
  }
}

.nv-button-blue-4 {
  @extend .nv-button;
  border-color: $blue-4;
  background-color: $blue-4;
  color: $white;

  &:hover, &:active {
    border-color: lighten($blue-4, 3%);
    background-color: lighten($blue-4, 3%);
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.nv-button-clear, a.nv-button-clear {
  @extend .nv-button;
  border: 1px solid $k-light-gray;
  background-color: transparent;
  color: $body-text-color;

  &.white-text {
    color: $white;
  }

  &.dark-border {
    border: 1px solid $k-gray;
  }

  &:hover, &:active {
    background-color: rgba($white, .5);
    color: $body-text-color;
  }

  &:focus {
    color: $body-text-color;
  }
}

.nv-button-clear-alt, a.nv-button-clear-alt {
  @extend .nv-button;
  border: 1px solid $k-gray;
  color: $k-darker-gray;
  padding: 12px 24px;
  line-height: 16px;
  width: unset;
  height: 42px;

  &:hover, &:active, &:focus {
    color: $k-darker-gray;
  }
}

.nv-button-clear-blue {
  @extend .nv-button;
  border-color: $k-blue;
  background-color: transparent;
  color: $k-blue;

  &:hover, &:active {
    background-color: rgba($white, .5);
    color: $k-blue;
  }

  &:focus {
    color: $k-blue;
  }
}

.primary-btn-night-blue {
  @extend .nv-button;
  border-color: $night-blue;
  background-color: $night-blue;
  color: $white;

  &:hover, &:active {
    border-color: lighten($night-blue, 3%);
    background-color: lighten($night-blue, 3%);
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.nv-button-square-white, a.nv-button-square-white {
  @extend .nv-button;
  width: 60px;
  padding: 18px;
  border: 1px solid $k-light-gray;
  background-color: $white;
  fill: $body-text-color;
  color: $body-text-color;

  &:hover, &:active {
    border-color: $body-text-color;
    background-color: $bg-color;
    color: $body-text-color;
  }

  &:focus {
    color: $body-text-color;
  }

  &.smaller {
    width: 40px;
    line-height: 16px;
  }
}

.nv-filter-button {
  @extend .nv-button;
  position: relative;
  padding-right: 60px;
  border: 1px solid $k-light-gray;
  background-color: $white;
  color: $body-text-color;
  text-align: left;

  &:hover, &:active {
    background-color: $bg-color;
    color: $body-text-color;
  }

  &:focus {
    color: $body-text-color;
  }

  .base-icon {
    position: absolute;
    top: 21px;
    right: 23px;
    width: 18px;
    height: 18px;
    fill: $gray-input-border;
  }

  &.selected {
    border: 0;
    color: $white;
    @include gradient-background($k-purple, $k-red);

    .base-icon {
      fill: rgba($white, .8);
    }

    &:hover, &:active {
      color: $white;
      @include gradient-background(darken($k-purple, 10%), darken($k-red, 10%));
    }
  }
}

.button-badge {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $k-blue;
  line-height: 15px;

  .base-icon {
    margin: 0 !important;
    fill: $white !important;
  }
}

.svg-button {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  user-select: none;

  &:hover {
    color: $k-blue;
    cursor: pointer;

    .base-icon {
      fill: $k-blue;
    }
  }

  &.disabled {
    color: $k-gray;

    .base-icon {
      fill: $k-gray;
    }

    &.green {
      color: $k-green;

      .base-icon {
        fill: $k-green;
      }
    }

    &:hover {
      cursor: default;
    }
  }
}

.empty-button {
  display: block;
  width: 100%;
  height: 60px;
  padding: 20px 15px;
  border: 1px dashed $k-darker-gray;
  border-radius: $border-radius-large;
  background-color: $white;
  color: $k-darker-gray;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  &:hover, &:active {
    border-color: $k-blue;
    color: $k-blue;
    text-decoration: none;
  }

  &.smaller {
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.solid {
    border: 1px solid $k-darker-gray;
  }

  &.inverted {
    background-color: $k-lighter-gray;

    &:hover, &:active {
      border-color: $k-darker-gray;
      background-color: $k-light-gray;
      color: $k-darker-gray;
    }

    &:focus {
      color: $k-darker-gray;
    }
  }

  &[disabled="disabled"] {
    border: 1px dashed $k-dark-gray;
    color: $k-dark-gray;
  }

  &.a-button-size {
    height: 46px;
    padding: 0 11px;

    @media (min-width: $screen-sm-min) {
      height: 42px;
    }
  }
}

.radio-select-button {
  width: 100%;
  height: 70px;
  padding: 15px;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
  outline: none;
  background-color: transparent;
  color: $body-text-color;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  appearance: none;
  user-select: none;

  @media (min-width: $screen-sm-min) {
    font-size: 16px;
  }

  .radio-select {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 15px;
    border: 1px solid $body-text-color;
    border-radius: 50%;
    vertical-align: middle;
  }

  &:hover, &:active {
    border-color: $k-blue;
    text-decoration: none;

    .radio-select {
      border-color: $k-blue;
    }
  }

  &.selected {
    border-color: $k-blue;
    background-color: rgba($k-blue, .05);

    .radio-select {
      border: 5px solid $k-blue;
    }
  }
}

.a-button-purple {
  @include a-button($white, $k-dark-purple);

  &.with-shadow {
    @include a-shadow;
  }
}

.feedback-button {
  width: 18px;
  height: 18px;
  padding: 0;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-small;
  outline: none;
  background-color: $bg-color;
  color: $k-darker-gray;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  appearance: none;

  &.plus-sign {
    &::before {
      content: "+";
    }
  }

  &.minus-sign {
    &::before {
      content: "-";
      position: relative;
      top: -1px;
    }
  }

  &:hover, &:active {
    background-color: $light-gray-bg;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    @include opacity(.5);
  }
}

.expand-icon {
  transition: transform .1s;

  &.expanded {
    transform: rotate(90deg);
  }
}

%compact-button {
  display: inline-block;
  height: 46px;
  padding: 13px 20px;
  border: 1px solid $k-blue;
  border-radius: $border-radius-large;
  outline: none;
  background-color: $k-blue;
  color: $white;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  appearance: none;

  @media (min-width: $screen-sm-min) {
    height: 42px;
    padding: 11px 20px;
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  &.disabled, &:disabled {
    cursor: default;
    pointer-events: none;
    @include opacity(.5);

    .base-icon {
      @include opacity(.5);
    }
  }
}

.primary-button-compact {
  @extend %compact-button;
  background-color: $night-blue;
  border-color: $night-blue;
  padding: 13px 30px;

  @media (min-width: $screen-sm-min) {
    padding: 11px 30px;
  }

  &:hover, &:active {
    border-color: lighten($night-blue, 3%);
    background-color: lighten($night-blue, 3%);
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.secondary-button-compact {
  @extend %compact-button;
  border: 1px solid $k-blue;
  background-color: $white;
  fill: $k-blue;
  color: $k-blue;

  &:hover, &:active {
    background-color: $k-blue;
    color: $white;
  }
}
