.padding-because-fixed-nav {
  padding-top: $nav-height;

  &.with-banner {
    padding-top: $nav-height + 120px;

    @media (min-width: $screen-sm-min) {
      padding-top: $nav-height + 80px;
    }
  }
}

.navbar.signed-out-nav {
  margin-bottom: 0;
  border: 0;
  background-color: $white;

  .navbar-header {
    .navbar-brand {
      height: 80px;
      padding: 20px 30px;

      .brand-icon {
        width: 210px;
        height: 40px;
        fill: $white;
      }
    }

    .navbar-toggle {
      margin: 29px 30px 29px 0;
      padding: 6px;

      .icon-bar {
        background-color: $body-text-color;
      }
    }
  }

  .navbar-nav {
    padding-right: 15px;

    @media (min-width: $screen-sm-min) {
      padding-right: 0;
    }

    &.navbar-right {
      padding-right: 15px;

      @media (min-width: $screen-sm-min) {
        padding-right: 30px;
      }
    }

    > li {
      @media (min-width: $screen-sm-min) {
        &.current, &.child-current {
          border-top: 4px solid $k-blue;

          > a, > .purple-text {
            margin-top: -4px;
            color: $k-blue;
          }
        }
      }

      > a {
        padding: 10px 15px;
        color: $body-text-color;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;

        @media (min-width: $screen-sm-min) {
          padding: 30px 8px;
        }

        @media (min-width: $screen-md-min) {
          padding: 30px 15px;
        }

        @media (min-width: $screen-lg-min) {
          padding: 30px 20px;
        }

        &:hover, &:active, &:focus {
          background-color: transparent;
          @include opacity(.75);
        }

        &.purple-text {
          color: $k-purple;
        }
      }
    }

    &:not(.navbar-right) > li > a {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
