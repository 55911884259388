@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400..700;1,400..700&display=swap');

body, h1, h2, h3, h4, h5, h6 {
  backface-visibility: hidden;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

body {
  font-size: 12px;
  line-height: 1.5;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: 16px;
    line-height: 1.4;
  }
}

.normal-weight {
  font-weight: 400;
}

.semibold-weight {
  font-weight: 600;
}

.bold-weight {
  font-weight: 700;
}

a, .pseudo-link-blue {
  color: $k-blue;
  text-decoration: none;
  cursor: pointer;

  &:hover, &:focus {
    color: $k-blue;
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }

  &[disabled] {
    color: $k-gray;
    cursor: default;
    pointer-events: none;

    .base-icon {
      fill: $k-gray;
    }

    &:hover, &:focus {
      color: $k-gray;
      text-decoration: none;
      cursor: default;

      .base-icon {
        fill: $k-gray;
      }
    }
  }
}

button.pseudo-link-blue {
  padding: 0;
  border: 0;
  background: transparent;
}

button.pseudo-link-gray {
  background: none;
  border: none;
  text-decoration: underline;
  font-size: inherit;
  padding: 0;
  color: $pill-gray;

  &:hover {
    color: $k-darker-gray;
  }
}

.light-gray-link {
  color: $k-dark-gray;
  text-decoration: underline;

  &:hover, &:focus {
    color: $k-darker-gray;
  }
}

.dark-gray-link {
  color: $body-text-color;
  text-decoration: underline;

  &:hover, &:focus {
    color: $darker-gray;
  }

  &.no-decoration {
    &:hover, &:focus {
      color: $body-text-color;
      text-decoration: underline;
    }
  }
}

.purple-link {
  color: $k-purple;

  &:hover, &:focus {
    color: $k-purple;
    text-decoration: underline;
  }
}

.back-link {
  color: $k-darker-gray;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;

  &:hover, &:focus {
    color: $k-darker-gray;
    text-decoration: underline;
  }

  .base-icon {
    vertical-align: text-bottom;
  }
}

.bold-link {
  color: $body-text-color;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;

  .base-icon {
    vertical-align: text-top;
  }

  &:hover, &:focus {
    color: $body-text-color;
  }

  &.blue-link {
    color: $k-blue;

    &:hover, &:focus {
      color: $k-blue;
    }

    .base-icon {
      fill: $k-blue;
    }

    &[disabled] {
      color: $k-gray;

      &:hover, &:focus {
        color: $k-gray;
      }
    }
  }

  &.blue-hover {
    &:hover, &:focus {
      color: $k-blue;

      .base-icon {
        fill: $k-blue;
      }
    }

    &[disabled] {
      &:hover, &:focus {
        color: $k-gray;

        .base-icon {
          fill: $k-gray;
        }
      }
    }
  }
}

.red-link {
  color: $k-red;

  &:hover, &:focus {
    color: $k-red;
  }

  .base-icon {
    fill: $k-red;
  }
}

.disabled-gray-link {
  color: $k-gray;

  &:hover, &:focus {
    color: $k-gray;
  }
}

b, strong {
  font-weight: 700;
}

.page-heading {
  color: $dark-blue;
  font-size: 1.3125em;
  font-weight: 600;
}

.page-sub-heading {
  color: $dark-blue;
  font-size: 1.125em;
  font-weight: 600;
}

.tiny-text {
  font-size: 12px;
  line-height: 1em;
}

.semi-large-text {
  font-size: 1.25em;
  line-height: 1.25;
}

.large-text {
  font-size: 1.75em;
  line-height: 1;
}

.blue-text {
  fill: $k-blue !important;
  color: $k-blue;

  .base-icon {
    fill: $k-blue;
  }
}

.marketplace-blue-text {
  color: $night-blue;
}

.high-match-score-blue-text {
  color: $high-match-score-blue;
}

.green-text {
  fill: $k-green !important;
  color: $k-green !important;

  .base-icon {
    fill: $k-green;
  }
}

.red-text {
  fill: $k-red !important;
  color: $k-red;

  .base-icon {
    fill: $k-red;
  }
}

.yellow-text {
  fill: $k-yellow !important;
  color: $k-yellow !important;

  .base-icon {
    fill: $k-yellow;
  }
}

.yellow-5-text {
  color: $yellow-5;
}

.purple-5-text {
  color: $purple-5;
}

.gray-1-text {
  color: $gray-1;
}

.gray-6-text {
  color: $gray-6;
}

.pill-gray-text {
  color: $pill-gray;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-link {
  fill: $body-text-color;
  color: $body-text-color;

  &:hover, &:focus {
    fill: $body-text-color;
    color: $body-text-color;
    text-decoration: none;
  }
}

.headline-1-small {
  margin: 0;
  padding: 0;
  color: $dark-blue;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

.kenny-styles {
  color: $k-darker-gray;
  font-size: 14px;
  line-height: 1.5;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: 16px;
    line-height: 1.4;
  }

  .white-text {
    color: $white;
  }

  .gray-text {
    color: $k-gray;
  }

  .dark-gray-text {
    fill: $k-dark-gray;
    color: $k-dark-gray;
  }

  .darker-gray-text {
    fill: $body-text-color;
    color: $body-text-color;
  }

  .pill-gray-text {
    color: $pill-gray;
  }

  .light-blue-k-text {
    color: $k-blue;
  }

  .blue-text {
    color: $dark-blue;
  }

  .purple-text {
    color: $k-purple;
  }

  .scout-purple-text {
    color: $wave-end-purple;
  }

  .orange-text {
    color: $k-orange;
  }

  .text-italic {
    font-style: italic;
  }

  .tight-lines {
    line-height: 1.3;
  }

  .line-height-1 {
    line-height: 1;
  }

  .body-medium-semibold {
    font-size: 1em;
    font-weight: 600;

    a {
      color: inherit;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .body-tiny-semibold {
    font-size: 11px;
    font-weight: 600;
    line-height: 1.4;
  }
}

.size-text-32px {
  font-size: 32px;
}

.size-text-30px {
  font-size: 30px;
}

.size-text-28px {
  font-size: 28px;
}

.size-text-26px {
  font-size: 26px;
}

.size-text-24px {
  font-size: 24px;
  line-height: 1.3;
}

.size-text-21px {
  font-size: 21px;
}

.size-text-20px {
  font-size: 20px;
}

.size-text-18px {
  font-size: 18px;
}

.size-text-17px {
  font-size: 17px;
}

.size-text-base {
  font-size: 16px;
}

.size-text-16px {
  font-size: 16px;
}

.size-text-15px {
  font-size: 15px;
}

.size-text-14px {
  font-size: 14px;
}

.size-text-13px {
  font-size: 13px;
}

.size-text-12px {
  font-size: 12px;
}

.size-text-11px {
  font-size: 11px;
}

.size-text-10px {
  font-size: 10px;
}

.size-text-9px {
  font-size: 9px;
}

%tiny-header-text {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
}

.tiny-header-text {
  @extend %tiny-header-text;
}

.tiny-gray-header-text {
  @extend %tiny-header-text;
  color: $k-gray;

  &:hover, &:focus {
    color: $k-gray;
  }
}

.small-header-text {
  font-size: 13px;
  font-weight: 600;
}

.small-bold-header {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.medium-page-heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3;
}

.large-page-heading {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}

.semitransparent {
  @include opacity(.6);
}

.underlined {
  text-decoration: underline;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

.heading-underline {
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 5px;
}

.offset-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.no-decoration {
  text-decoration: none;
}

.semitransparent-link {
  text-decoration: underline;
  @include opacity(.6);

  &:hover, &:focus {
    text-decoration: underline;
    @include opacity(.8);
  }
}

.wrap-word {
  overflow-wrap: break-word;
  word-break: break-word;
}

.missing-text {
  color: $k-dark-gray;
  font-style: italic;
}

.disabled-text {
  color: $k-gray;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-nowrap {
  white-space: nowrap;
}

.simple-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.vertical-middle-text {
  vertical-align: middle;
}

.vertical-top-text {
  vertical-align: text-top;
}

.reverse-link {
  color: $k-dark-gray;
  text-decoration: underline;

  &:hover, &:focus {
    color: $k-darker-gray;
    text-decoration: underline;
  }
}
