body {
  background-color: $k-lighter-gray;
}

hr {
  border-color: $grey-3;

  &.tight {
    margin: 20px 0;
  }
}

dt {
  font-weight: 600;
}

.brand-icon {
  display: inline-block;
  width: 160px;
  height: 30px;
  fill: $k-darker-gray;
  vertical-align: middle;
}

.main-container {
  min-height: calc(100vh - #{$nav-height} - #{$footer-height});
  padding: 30px 0;

  @media (min-width: $screen-sm-min) {
    padding: 20px 5px;
  }

  @media (min-width: $screen-md-min) {
    padding: 30px 15px;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 1410px;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 25px;
  }
}

.padded-container {
  padding: 5px;

  @media (min-width: $screen-sm-min) {
    padding: 20px;

    &.less-padded {
      padding-top: 5px;
    }
  }

  @media (min-width: $screen-md-min) {
    padding: 30px;

    &.less-padded {
      padding-top: 15px;
    }
  }

  @media (min-width: $screen-lg-min) {
    padding: 40px;
  }
}

.fixed-container {
  max-width: 1280px;
  margin: 0 auto;
}

.relative-container {
  position: relative;
}

.headline {
  margin-top: 16px;
  margin-bottom: 16px;
}

.content {
  border: 1px solid $grey-4;
  background: $white;
}

.box {
  padding: 20px;

  @media (min-width: $screen-sm-min) {
    padding: 30px;
  }
}

%priori-panel {
  position: relative;
  margin: 0 0 20px;
  padding: 1em;
  border: 1px solid $k-yellow;
  border-radius: $border-radius-small;
  background-color: $k-light-yellow-bg;

  a {
    text-decoration: underline;
  }

  p {
    margin: 0 0 1em;
  }
}

.priori-panel {
  @extend %priori-panel;
}

.green-panel {
  @extend %priori-panel;
  border: 1px solid $k-green;
  background-color: $k-green-background;
}

.red-panel {
  @extend %priori-panel;
  border: 1px solid $k-red;
  background-color: $error-background;
}

.gray-panel {
  @extend %priori-panel;
  border: 1px solid $k-light-gray;
  background-color: $bg-color;
}

.gray-modern-panel {
  @extend %priori-panel;
  margin: 0;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
  background-color: $bg-color;
}

.clear-panel {
  padding: 20px;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
  background-color: transparent;
  line-height: 20px;

  &.compact-panel {
    padding: 15px 20px;
  }

  &.variable-padded {
    padding: 15px;

    @media (min-width: $screen-sm-min) {
      padding: 20px;
    }

    @media (min-width: $screen-md-min) {
      padding: 30px;
    }
  }

  &.no-padding {
    padding: 0;
  }
}

%notification-panel {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: $border-radius-base;
  font-size: 13px;
  font-weight: 400;
}

.notification-panel-info {
  @extend %notification-panel;
  border: 1px solid $k-gray;
  background-color: $k-light-gray;
  color: $k-darker-gray;
}

.notification-panel-warn {
  @extend %notification-panel;
  border: 1px solid $k-yellow-shadow;
  background-color: $k-light-yellow-bg;
  color: $k-yellow-shadow;

  &.compact {
    padding: 10px;
  }
}

.notification-panel-success {
  @extend %notification-panel;
  border: 1px solid $k-green;
  background-color: $k-light-green-bg;
  color: $k-green;
}

.notification-panel-error {
  @extend %notification-panel;
  border: 1px solid $k-red;
  background-color: $error-background;
  color: $k-red;
}

.top-notification {
  margin: 0;
  border-width: 0 0 1px;
  border-radius: 0;
}

$margin-sizes: [0, 2, 4, 5, 6, 10, 15, 18, 20, 25, 30, 40, 50, 60, 70, 80, 100, 150];

@each $size in $margin-sizes {
  .top-#{$size} {
    margin-top: #{$size}px;
  }

  .bottom-#{$size} {
    margin-bottom: #{$size}px;
  }

  .top-#{$size}-xs {
    margin-top: #{$size}px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }

  .bottom-#{$size}-xs {
    margin-bottom: #{$size}px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }
}

.top-10-xs-sm {
  margin-top: 10px;

  @media (min-width: $screen-md-min) {
    margin-top: 0;
  }
}

.right-3 {
  margin-right: 3px;
}

.right-5 {
  margin-right: 5px;
}

.right-7 {
  margin-right: 7px;
}

.right-10 {
  margin-right: 10px !important;
}

.right-10-not-xs {
  margin-right: 0;

  @media (min-width: $screen-sm-min) {
    margin-right: 10px;
  }
}

.right-15 {
  margin-right: 15px !important;
}

.right-20 {
  margin-right: 20px !important;
}

.right-20-not-xs {
  margin-right: 0;

  @media (min-width: $screen-sm-min) {
    margin-right: 20px !important;
  }
}

.right-30 {
  margin-right: 30px !important;
}

.right-30-not-xs {
  margin-right: 0;

  @media (min-width: $screen-sm-min) {
    margin-right: 30px !important;
  }
}

.right-30-60 {
  margin-right: 30px !important;

  @media (min-width: $screen-sm-min) {
    margin-right: 60px !important;
  }
}

.left-5 {
  margin-left: 5px;
}

.left-10 {
  margin-left: 10px !important;
}

.left-15 {
  margin-left: 15px !important;
}

.left-15-not-xs {
  margin-left: 0;

  @media (min-width: $screen-sm-min) {
    margin-left: 15px;
  }
}

.left-20 {
  margin-left: 20px !important;
}

.left-30 {
  margin-left: 30px !important;
}

.left-30-not-xs {
  margin-right: 0;

  @media (min-width: $screen-sm-min) {
    margin-left: 30px !important;
  }
}

.left-64 {
  margin-left: 64px !important;
}

$padding-sizes: [20, 30];

@each $size in $padding-sizes {
  .inner-#{$size} {
    padding: #{$size}px;
  }
}

.no-bullets {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.clear {
  clear: both;
}

.no-padding {
  padding: 0;
}

.blue-badge {
  @include priori-badge($k-blue);
}

.logo-large {
  max-width: 180px;
  max-height: 180px;
}

.bg-gray {
  background-color: $k-lighter-gray;
}

.fixed {
  position: fixed;
}

.height-100 {
  height: 100%;
}

.row.no-gutter {
  margin-right: 0;
  margin-left: 0;

  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.row.tight-columns {
  margin-right: -5px;
  margin-left: -5px;

  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.row.md-columns {
  margin-right: -10px;
  margin-left: -10px;

  > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.row.gutter-20 {
  margin-right: -20px;
  margin-left: -20px;

  > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.modal-md-sm {
  @media (min-width: $screen-sm-min) {
    width: 500px;
  }
}

.modal-md-lg {
  @media (min-width: $screen-sm-min) {
    width: 700px;
  }
}

.new-icon {
  display: inline-block;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
}

.base-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  fill: $k-darker-gray;
  vertical-align: middle;

  &.medium {
    width: 24px;
    height: 24px;
  }

  &.larger {
    width: 30px;
    height: 30px;
    vertical-align: bottom;
  }

  &.largest {
    width: 60px;
    height: 60px;
    vertical-align: bottom;
  }

  &.smaller {
    width: 14px;
    height: 14px;
  }

  &.smallest {
    width: 7px;
    height: 7px;
  }

  &.super-icon {
    vertical-align: super;
  }

  &.text-top {
    vertical-align: text-top;
  }

  &.lighter-gray {
    fill: $k-dark-gray;
  }

  &.red {
    fill: $k-red;
  }
}

.d-flex {
  display: flex;

  &.wrap {
    flex-wrap: wrap;
  }
}

.d-flex-not-xs {
  @media (min-width: $screen-sm-min) {
    display: flex;
  }
}

.flex-center {
  justify-content: center;
}

.flex-center-not-xs {
  justify-content: flex-start;

  @media (min-width: $screen-sm-min) {
    justify-content: center;
  }
}

.flex-end {
  justify-content: flex-end;
}

.flex-end-not-xs {
  justify-content: flex-start;

  @media (min-width: $screen-sm-min) {
    justify-content: flex-end;
  }
}

.flex-space-between {
  justify-content: space-between;
}

.vertical-center {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.vertical-center-not-xs {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    min-height: 100%;
  }
}

.vertical-bottom-not-xs {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: flex-end;
    min-height: 100%;
  }
}

.h-100 {
  height: 100%;
}

.h-100-not-xs {
  @media (min-width: $screen-sm-min) {
    height: 100%;
  }
}

.text-right-not-xs {
  margin-top: 10px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    text-align: right;
  }
}

.nowrap {
  white-space: nowrap;
}

$gap-sizes: [2, 4, 5, 6, 8, 10, 11, 15, 20, 30, 40, 50];

@each $size in $gap-sizes {
  .row-gap-#{$size} {
    row-gap: #{$size}px;
  }

  .col-gap-#{$size} {
    column-gap: #{$size}px;
  }
}

@for $flex-size from 1 through 20 {
  .flex-#{$flex-size} {
    flex: $flex-size;
  }
}

hr.kenny-hr {
  margin: 20px 0;
  border-color: $k-light-gray;

  &.no-margin {
    margin: 0 !important;
  }

  &.margin-5 {
    margin: 5px 0;
  }

  &.tight {
    margin: 12px 0;
  }

  &.wide {
    margin: 40px 0;
  }
}

.white-bg {
  background-color: $white;
}

.display-none {
  display: none;
}

// Add fifths to bootstrap 12-column layout
.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5,
.col-xs-2-5,
.col-sm-2-5,
.col-md-2-5,
.col-lg-2-5,
.col-xs-3-5,
.col-sm-3-5,
.col-md-3-5,
.col-lg-3-5,
.col-xs-4-5,
.col-sm-4-5,
.col-md-4-5,
.col-lg-4-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1-5 {
  width: 20%;
  float: left;
}

.col-xs-2-5 {
  width: 40%;
  float: left;
}

.col-xs-3-5 {
  width: 60%;
  float: left;
}

.col-xs-4-5 {
  width: 80%;
  float: left;
}

@media (min-width: $screen-sm-min) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }

  .col-sm-2-5 {
    width: 40%;
    float: left;
  }

  .col-sm-3-5 {
    width: 60%;
    float: left;
  }

  .col-sm-4-5 {
    width: 80%;
    float: left;
  }
}

@media (min-width: $screen-md-min) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }

  .col-md-2-5 {
    width: 40%;
    float: left;
  }

  .col-md-3-5 {
    width: 60%;
    float: left;
  }

  .col-md-4-5 {
    width: 80%;
    float: left;
  }
}

@media (min-width: $screen-lg-min) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }

  .col-lg-2-5 {
    width: 40%;
    float: left;
  }

  .col-lg-3-5 {
    width: 60%;
    float: left;
  }

  .col-lg-4-5 {
    width: 80%;
    float: left;
  }
}

.purple-red-gradient {
  @include gradient-background($k-purple, $k-red);
}

.blue-purple-straight-gradient {
  background: $k-blue;
  background: linear-gradient(90deg, $k-blue, $k-purple);
}

.primary-marketplace-gradient {
  background: #0A1370;
  background: linear-gradient(45deg, #0A1370, #610779);
  color: $white;
}

.primary-marketplace-gradient-90 {
  background: #0A1370;
  background: linear-gradient(90deg, #0A1370, #610779);
  color: $white;
}

.secondary-marketplace-gradient {
  background: #62087A;
  background: linear-gradient(45deg, #62087A, #AF2E8D);
  color: $white;
}

.display-table {
  display: table;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  float: none;

  &.vertically-centered {
    vertical-align: middle;
  }
}

%shadowed-box {
  position: relative;
  min-height: 70px;
  border-radius: $border-radius-large;
  background-color: $white;
  @include k-shadow;

  .box-header {
    padding: 20px 30px;
    border-radius: $border-radius-large $border-radius-large 0 0;
    background-color: $bg-color;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;

    &.invoice-header, &.large-header {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
    }

    &.less-padded {
      padding: 15px 30px;
      line-height: 30px;
    }

    &.compact {
      padding: 10px 30px;
    }

    .matter-icon {
      fill: $k-darker-gray;
      vertical-align: top;
    }
  }

  .box-notification-error {
    padding: 10px 30px;
    background-color: $error-background;
    color: $k-red;
    font-size: 11px;
    font-weight: 600;
  }

  .box-content {
    padding: 20px 30px;

    &.symmetrical {
      padding: 30px;

      .kenny-hr {
        margin: 30px 0;

        &.normal {
          margin: 20px 0;
        }
      }
    }

    &.compressed {
      padding: 10px 30px;

      .kenny-hr {
        margin: 10px 0;
      }
    }

    &.contracted {
      height: 50px;
      overflow: hidden;
    }

    &.variable-padded {
      padding: 20px;

      @media (min-width: $screen-sm-min) {
        padding: 30px;
      }

      @media (min-width: $screen-md-min) {
        padding: 50px;
      }

      .kenny-hr.full-width {
        margin: 0 -20px;

        @media (min-width: $screen-sm-min) {
          margin: 0 -30px;
        }

        @media (min-width: $screen-md-min) {
          margin: 0 -50px;
        }
      }
    }

    &.variable-less-padded {
      padding: 20px;

      @media (min-width: $screen-sm-min) {
        padding: 30px;
      }

      @media (min-width: $screen-md-min) {
        padding: 40px;
      }
    }

    &.contrasting {
      background-color: $bg-color;

      &:last-child {
        border-bottom-right-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
      }
    }

    &.extra-padded {
      padding: 30px;

      .kenny-hr {
        margin: 30px 0;
      }

      @media (min-width: $screen-md-min) {
        padding: 50px;

        .kenny-hr {
          margin: 50px 0;
        }
      }
    }

    .table th {
      padding: 0 10px 10px 0;

      &:last-child {
        padding-right: 0;
      }
    }

    @media (max-width: $screen-xs-max) {
      .table-responsive .table th:last-child {
        padding-right: 10px;
      }
    }
  }

  .upper-right {
    position: absolute;
    right: 30px;
    z-index: 10;
  }

  .box-footer {
    padding: 20px 25px;
    border-bottom-right-radius: $border-radius-large;
    border-bottom-left-radius: $border-radius-large;
    background-color: $bg-color;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
  }
}

.shadowed-box {
  @extend %shadowed-box;
}

.wired-box {
  background-color: $white;
  border: 1px solid $k-gray;
  border-radius: $border-radius-base;
  position: relative;

  .box-header {
    padding: 15px 20px;
    border-bottom: 1px solid $k-gray;
    border-radius: $border-radius-base $border-radius-base 0 0;
    background-color: $bg-color;
  }

  .box-content {
    padding: 0 15px;
  }
}

.green-shadowed-box {
  @extend %shadowed-box;
  background: linear-gradient(45deg, $green-shadow, $k-green);
  background-color: $green-shadow;
  color: $white;

  .box-header {
    background-color: $k-green;
  }
}

.list-card {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 30px;
  border-radius: $border-radius-large;
  background-color: $white;
  font-weight: 400;
  line-height: 1.3;
  @include k-shadow;

  @media (min-width: $screen-md-min) {
    height: 100px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .list-card-title {
    margin-bottom: 20px;
    font-size: 24px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .list-card-subtext {
    font-size: 13px;
  }

  .list-card-middle {
    text-align: left;

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
  }

  .list-card-status {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .list-card-btn {
    margin-top: 30px;

    @media (min-width: $screen-md-min) {
      margin-top: 0;
    }
  }
}

.tooltip.k-tooltip {
  z-index: 1029;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;

  .tooltip-inner {
    max-width: 300px;
    padding: 8px 16px;
    background-color: $k-darker-gray;
    color: $white;
    text-align: left;
    @include k-shadow;
  }

  &.top .tooltip-arrow {
    border-top-color: $k-darker-gray;
  }

  &.right .tooltip-arrow {
    border-right-color: $k-darker-gray;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: $k-darker-gray;
  }

  &.left .tooltip-arrow {
    border-left-color: $k-darker-gray;
  }

  &.in {
    @include opacity(1);
  }

  &.tooltip-white {
    font-size: 13px;
    font-weight: 400;

    .tooltip-inner {
      max-width: 200px;
      padding: 20px;
      background-color: $white;
      color: $k-darker-gray;
    }

    &.top .tooltip-arrow {
      border-top-color: $white;
    }

    &.bottom .tooltip-arrow {
      border-bottom-color: $white;
    }
  }

  &.small-text {
    font-size: 11px;
    line-height: 15px;
  }

  &.over-modal {
    z-index: 1071;
  }
}

.tooltip-question {
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 1px solid $k-gray;
  border-radius: $border-radius-small;
  color: $k-gray;
  font-size: 11px;
  line-height: 11px;
  text-align: center;

  &:hover {
    border: 1px solid $k-darker-gray;
    color: $k-darker-gray;
    cursor: pointer;
  }
}

ul.dashed {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    padding-left: 13px;
    text-indent: -13px;
  }

  > li::before {
    content: "-";
    padding-right: 8px;
  }
}

ul.compact {
  margin: 0;
  padding: 0;
  list-style-position: inside;

  > li {
    padding-left: 20px;
    text-indent: -20px;
  }
}

.k-footer {
  height: $footer-height;
  padding: 40px 30px 30px;
  background-color: $light-gray-bg;

  .base-icon {
    width: 30px;
    height: 30px;
    fill: $k-gray;
  }
}

.clickable {
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.block-anchor {
  display: block;
  text-decoration: none;
  user-select: none;

  &:hover, &:focus {
    text-decoration: none;

    .target {
      text-decoration: underline;
    }
  }

  &.disabled {
    pointer-events: none;
    user-select: auto;

    &:hover, &:focus {
      cursor: text;

      .target {
        text-decoration: none;
      }
    }
  }
}

.animate-change {
  transition: color .3s ease-in-out, font-weight .3s ease-in-out;

  &.animating {
    color: $k-blue;
    font-weight: 600;
  }
}

.overlay-container {
  position: relative;

  .bottom-overlay {
    position: absolute;
    right: 1px;
    bottom: 1px;
    left: 1px;
    height: 40px;
    padding: 10px 40px;
    border-top: 1px solid $k-light-gray;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    background-color: $bg-color;
    line-height: 20px;
  }

  .textarea-overlay {
    position: absolute;
    right: 35px;
    bottom: 1px;
    left: 35px;
    height: 35px;
    padding-top: 5px;
    background-color: $white;
    color: $k-blue;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;

    .base-icon {
      width: 12px;
      height: 14px;
      fill: $k-blue;
    }
  }

  .input-overlay {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $k-blue;
    font-size: 13px;
    line-height: 20px;
  }
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.user-pic-container {
  display: inline-block;
  position: relative;

  &.editing {
    .user-pic {
      filter: grayscale(100%) opacity(.15);
    }

    .user-pic:hover, .missing-photo:hover {
      cursor: pointer;
    }
  }
}

.edit-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $k-darker-gray;
  font-weight: 600;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.user-pic {
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba($black, .2);
  filter: grayscale(100%);

  &.larger-pic {
    width: 80px;
    height: 80px;
  }

  &.medium-pic {
    width: 50px;
    height: 50px;
  }

  &.small-pic {
    width: 40px;
    height: 40px;
  }

  &.smallest-pic {
    width: 30px;
    height: 30px;
  }
}

.tag-list {
  margin-bottom: -10px;

  .tag-list-item {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: $border-radius-small;
    background-color: $k-blue;
    color: $white;
    font-weight: 600;

    &:last-child {
      margin-right: 0;
    }

    &.purple-red {
      @include gradient-background($k-purple, $k-red);
    }

    &.blue {
      background-color: $k-blue;
    }

    &.green {
      background-color: $k-green;
    }

    &.black {
      background-color: $k-darker-gray;
    }

    &.gray {
      background-color: $k-light-gray;
      color: $body-text-color;
      font-weight: 400;

      .tag-list-item-delete {
        .base-icon {
          fill: $body-text-color;
        }
      }
    }

    &.clear {
      border: 1px solid $k-light-gray;
      background-color: transparent;
      color: $body-text-color;
    }

    &.empty {
      border: 1px dashed $k-darker-gray;
      border-radius: $border-radius-large;
      background-color: $white;
      color: $k-darker-gray;

      &:hover, &:active {
        border-color: $k-blue;
        color: $k-blue;
        cursor: pointer;
      }
    }

    &.compact {
      padding: 5px 10px;
    }

    &.clickable {
      &:hover, &:focus {
        text-decoration: none;
      }

      &:hover {
        background-color: $bg-color;
      }
    }

    &.delete-variant {
      padding-right: 10px;

      .tag-list-item-delete {
        @include opacity(.3);

        &:hover {
          @include opacity(.7);
        }

        .base-icon {
          vertical-align: text-top;
        }
      }
    }

    .tag-list-item-delete {
      width: 18px;
      height: 18px;
      text-align: center;

      .base-icon {
        fill: $white;
        line-height: 5px;
        cursor: pointer;
      }
    }
  }
}

.status-indicator {
  padding-bottom: 3px;

  &.green {
    color: $k-green;
  }

  &.yellow {
    color: $k-yellow-dark;
  }

  &.purple {
    color: $k-purple;
  }

  &.blue {
    color: $k-blue;
  }

  &.gray {
    color: $k-dark-gray;
  }

  &.black {
    color: $k-darker-gray;
  }
}

.sidenav-container {
  padding-top: $nav-height;

  @media (min-width: $screen-md-min) {
    display: table;
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
    table-layout: fixed;
  }

  &.impersonating {
    padding-top: $nav-height + 25px;

    @media (min-width: $screen-md-min) {
      padding-top: 25px;
    }
  }

  .k-footer {
    margin-top: 25px;
    background-color: $k-lighter-gray;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      margin-top: 10px;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 0;
    }

    .base-icon {
      width: 38px;
      height: 38px;
      fill: $k-dark-gray;
    }
  }
}

.sidenav-main {
  vertical-align: top;

  @media (min-width: $screen-md-min) {
    display: table-cell;
    width: 80%;
  }
}

.sidenav-content {
  max-width: 1152px;
  margin-right: auto;
  padding-top: 15px;

  @media (min-width: $screen-md-min) {
    min-height: calc(100vh - #{$footer-height});
  }

  &.with-breadcrumbs .padded-container {
    @media (min-width: $screen-sm-min) {
      padding-top: 5px;
    }

    @media (min-width: $screen-md-min) {
      padding-top: 15px;
    }
  }

  &.full-width {
    max-width: none;
  }
}

.breadcrumbs-container {
  padding: 20px 0;
  background-color: $light-gray-bg;
  line-height: 20px;

  @media (min-width: $screen-md-min) {
    height: 80px;
    padding: 30px 0;
  }

  &.white-bar {
    height: 60px;
    background-color: $light-gray-bg;

    @media (max-width: $screen-xs-max) {
      .container-fluid {
        padding: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      height: 80px;
    }

    .switcher {
      display: inline-block;
      width: 160px;
      margin-right: -3px;
      padding-bottom: 16px;
      color: $k-dark-gray;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;

      @media (min-width: $screen-sm-min) {
        padding-bottom: 17px;
        font-size: 13px;
      }

      @media (min-width: $screen-md-min) {
        width: 170px;
        padding-bottom: 27px;
      }

      &:hover, &:focus {
        color: $k-darker-gray;
        text-decoration: none;
      }

      &.selected {
        border-bottom: 3px solid $k-blue;
        border-width: 0 0 3px;
        color: $k-darker-gray;
        border-image: linear-gradient(45deg, #62087A, #AF2E8D) 1;
      }
    }
  }

  .container-fluid {
    max-width: 1152px;
    margin-left: 0;
    padding-right: 20px;
    padding-left: 20px;

    @media (min-width: $screen-sm-min) {
      padding-right: 35px;
      padding-left: 35px;
    }

    @media (min-width: $screen-md-min) {
      padding-right: 45px;
      padding-left: 45px;
    }

    @media (min-width: $screen-lg-min) {
      padding-right: 55px;
      padding-left: 55px;
    }
  }
}

.impersonation-bubble {
  position: fixed;
  z-index: 50;
  bottom: 20px;
  left: 20px;

  button {
    padding: 10px 25px 10px 20px;
    border: 0;
    border-radius: 30px;
    background-color: $k-red;
    color: $white;
    font-size: 13px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-link {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border-radius: 50%;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;

      &:hover {
        background-color: rgba($white, .3);
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }
    }
  }
}

.impersonation-container {
  position: fixed;
  z-index: 5;
  top: 80px;
  width: 100%;
  height: 50px;
  background-color: $k-red;

  @media (min-width: $screen-md-min) {
    top: 0;
    width: calc(100% - #{$sidenav-width});
  }

  .message {
    padding-top: 16px;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    text-align: center;

    @media (min-width: $screen-md-min) {
      padding-top: 15px;
      font-size: 14px;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

// NOTE: when using this, you must include all column sizes and they must be the same
.auto-clear {
  @media (min-width: $screen-lg-min) {
    @include auto-clear(lg);
  }
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include auto-clear(md);
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @include auto-clear(sm);
  }
  @media (max-width: $screen-xs-max) {
    @include auto-clear(xs);
  }
}

.list-pagination {
  .pagination {
    margin: 0;
    font-weight: 600;

    > li > a, > li > span {
      padding: 0 8px;
      border: 0;
      background-color: transparent;
      color: $k-darker-gray;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }

    > li:first-child > a, > li:first-child > span {
      padding-left: 0;
    }

    > li:last-child > a, > li:last-child > span {
      padding-right: 0;
    }

    > .active > a, > .active > span {
      &, &:hover, &:focus {
        background-color: transparent;
        color: $k-blue;
        text-decoration: underline;
      }
    }

    > .disabled > a, > .disabled > span {
      &, &:hover, &:focus {
        background-color: transparent;
        color: $k-gray;
        text-decoration: none;
      }
    }
  }
}

.resource-list {
  &.table-responsive {
    padding: 0;
    border: 0;
  }

  .list-header {
    padding: 15px 20px 15px 30px;
    border-bottom: 1px solid $k-gray;
    background-color: $k-light-gray;
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: $screen-sm-min) {
      height: 50px;
    }
  }

  .list-item {
    position: relative;
    height: 80px;
    padding: 15px 20px 15px 30px;
    border-bottom: 1px solid $k-gray;
    background-color: $white;

    &.single-item {
      border-bottom: 0;
      background-color: transparent;
    }

    &.empty {
      padding-top: 28px;
      padding-bottom: 28px;
      line-height: 23px;
    }
  }

  .table {
    margin-bottom: 0;

    thead {
      border-bottom: 1px solid $k-gray;
      background-color: $k-light-gray;
      text-transform: uppercase;
    }

    th {
      color: $k-darker-gray;
      font-size: 13px;
      font-weight: 600;
    }

    tbody > tr:last-child {
      td {
        padding-bottom: 15px;
        border-bottom: 1px solid $k-light-gray;
      }
    }

    td, th {
      padding: 15px 10px;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }

      &.photo-cell {
        width: 80px;
      }
    }
  }
}

.list-spacing-5 {
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

.list-spacing-8 {
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.list-spacing-10 {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.list-spacing-15 {
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

.list-spacing-20 {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.list-spacing-30 {
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.yellow-message-box {
  background: $p-yellow-bg;
  border-left: 7px solid $p-yellow;
  border-radius: $border-radius-large;
  display: flex;
  padding: 10px;

  .alert-icon-container {
    width: 25px;

    .alert-icon {
      width: 16px;
      height: 16px;
      border: 1px solid $p-yellow;
      border-radius: 50%;
      color: $p-yellow;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
    }
  }

  .warning-icon {
    fill: $p-yellow;
  }

  .message {
    font-size: 13px;
    font-weight: 600;
    width: calc(100% - 30px);

    .link {
      color: $body-text-color;
      text-decoration: underline;
    }
  }
}

.green-message-box {
  background: $green-10;
  border-left: 7px solid $green-4;
  border-radius: $border-radius-large;
  display: flex;
  padding: 10px;

  .alert-icon-container {
    width: 25px;

    .alert-icon {
      width: 16px;
      height: 16px;
      border: 1px solid $green-4;
      border-radius: 50%;
      color: $green-4;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
    }
  }

  .warning-icon {
    fill: $green-4;
  }

  .message {
    font-size: 13px;
    font-weight: 600;
    width: calc(100% - 30px);

    .link {
      color: $body-text-color;
      text-decoration: underline;
    }
  }
}

.faded-section {
  &.fade-down::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba($white, 0) 0%, $white 100%);
    pointer-events: none;
  }

  &.fade-up::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, $white 0%, rgba($white, 0) 100%);
    pointer-events: none;
  }
}
